import React from 'react';

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
  },
  heading1: {
    textAlign: 'center',
    color: 'black',
  },
  lastUpdated: {
    textAlign: 'black',
    fontStyle: 'italic',
    marginBottom: '20px',
  },
  heading2: {
    color: 'black',
    borderBottom: '2px solid black',
    paddingBottom: '5px',
  },
  heading3: {
    color: 'black',
    borderBottom: '1px solid black',
    paddingBottom: '3px',
    marginTop: '20px',
  },
  heading4: {
    color: 'black',
  },
  list: {
    marginLeft: '20px',
  },
  link: {
    color: '#4A90E2',
    textDecoration: 'none',
  },
  linkHover: {
    textDecoration: 'underline',
  },
};

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading1}>Privacy Policy</h1>
      <p style={styles.lastUpdated}>Last updated: August 20, 2024</p>
      <p>
        This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You. We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
      </p>
      <h2 style={styles.heading2}>Interpretation and Definitions</h2>
      <h3 style={styles.heading3}>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
      </p>

      <h3 style={styles.heading3}>Definitions</h3>
      <ul style={styles.list}>
        <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
        <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>
        <li><strong>Application</strong> refers to Business Contact, the software program provided by the Company.</li>
        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Habsy Technologies Private Limited, #1/29, 78, Reddy Residency, 1st Floor, Kallubalu Rd, Sri Manjunatha Nagara, Bangalore, Karnataka, India, 560105.</li>
        <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
        <li><strong>Country</strong> refers to: Tamil Nadu, India.</li>
        <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
        <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
        <li><strong>Service</strong> refers to the Application or the Website or both.</li>
        <li><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company.</li>
        <li><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself.</li>
        <li><strong>Website</strong> refers to Habsy Technologies, accessible from <a href="https://habsy.in/" style={styles.link} onMouseOver={(e) => e.target.style.textDecoration = styles.linkHover.textDecoration} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>https://habsy.in/</a>.</li>
        <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
      </ul>

      <h2 style={styles.heading2}>Collecting and Using Your Personal Data</h2>
      <h3 style={styles.heading3}>Types of Data Collected</h3>

      <h4 style={styles.heading4}>Personal Data</h4>
      <p>
        While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
      </p>
      <ul style={styles.list}>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Usage Data</li>
      </ul>

      <h4 style={styles.heading4}>Usage Data</h4>
      <p>
        Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
      </p>

      <h4 style={styles.heading4}>Information Collected while Using the Application</h4>
      <p>
        While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:
      </p>
      <ul style={styles.list}>
        <li>Information regarding your location</li>
        <li>Information from your Device's phone book (contacts list)</li>
        <li>Pictures and other information from your Device's camera and photo library</li>
      </ul>
      <p>
        We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device. You can enable or disable access to this information at any time, through Your Device settings.
      </p>

      <h4 style={styles.heading4}>Tracking Technologies and Cookies</h4>
      <p>
        We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:
      </p>
      <ul style={styles.list}>
        <li><strong>Cookies or Browser Cookies:</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.</li>
        <li><strong>Web Beacons:</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons that permit the Company to count users who have visited those pages or opened an email.</li>
      </ul>

      <h3 style={styles.heading3}>Use of Your Personal Data</h3>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul style={styles.list}>
        <li><strong>To provide and maintain our Service:</strong> including to monitor the usage of our Service.</li>
        <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service.</li>
        <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased.</li>
        <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication.</li>
        <li><strong>To provide You:</strong> with news, special offers and general information about other goods, services and events.</li>
        <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
        <li><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets.</li>
        <li><strong>For other purposes:</strong> We may use Your information for other purposes, such as data analysis, identifying usage trends, and determining the effectiveness of our promotional campaigns.</li>
      </ul>

      <h3 style={styles.heading3}>Retention of Your Personal Data</h3>
      <p>
        The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations.
      </p>

      <h3 style={styles.heading3}>Transfer of Your Personal Data</h3>
      <p>
        Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. This information may be transferred to — and maintained on — computers located outside of Your jurisdiction.
      </p>

      <h3 style={styles.heading3}>Delete Your Personal Data</h3>
      <p>
        You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You. Our Service may give You the ability to delete certain information about You from within the Service.
      </p>

      <h3 style={styles.heading3}>Disclosure of Your Personal Data</h3>
      <h4 style={styles.heading4}>Business Transactions</h4>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred.
      </p>

      <h4 style={styles.heading4}>Law enforcement</h4>
      <p>
        Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities.
      </p>

      <h3 style={styles.heading3}>Security of Your Personal Data</h3>
      <p>
        The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.
      </p>

      <h3 style={styles.heading3}>Children's Privacy</h3>
      <p>
        Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13.
      </p>

      <h3 style={styles.heading3}>Links to Other Websites</h3>
      <p>
        Our Service may contain links to other websites that are not operated by Us. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
      </p>

      <h3 style={styles.heading3}>Changes to this Privacy Policy</h3>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.
      </p>

      <h3 style={styles.heading3}>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <ul style={styles.list}>
        <li>By email: <a href="mailto:habsy.tech@gmail.com" style={styles.link} onMouseOver={(e) => e.target.style.textDecoration = styles.linkHover.textDecoration} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>habsy.tech@gmail.com</a></li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
