import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyles = createGlobalStyle`
   .Toastify__toast-container {
    width: 250px !important;  
    right: 0 !important;
    left: auto !important;
  }

  .Toastify__toast {
    min-height: 40px !important;  
    font-size: 12px !important;   
    padding: 10px !important;    
  }
`;

const EnquiryFormMobile = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    enquireMessage: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formElement = e.target;
    fetch('https://formspree.io/f/xovadpbn', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Form submitted successfully!', { autoClose: 2000 });
          formElement.reset();
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            enquireMessage: ''
          });
        } else {
          toast.error('Form submission failed.', { autoClose: 2000 });
        }
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        toast.error('Form submission failed.', { autoClose: 2000 });
      });
  };

  const styles = {
    enquiryForm: {
      maxWidth: '600px',
      margin: 'auto',
      padding: '20px',
      border: 'none',
      borderRadius: '10px',
      backgroundColor: '#fff',
      height: '85vh',
      paddingBottom: '200px'
    },
    heading: {
      textAlign: 'center',
      marginBottom: '1.5rem',
      fontSize: '24px',
      fontWeight: 'bold'
    },
    formLabel: {
      fontWeight: 'bold',
    },
    formControl: {
      borderRadius: '5px',
      border: '1px solid #ccc',
      padding: '10px',
    },
    button: {
      backgroundColor: '#007bff',
      borderColor: '#007bff',
      padding: '10px',
      fontSize: '18px',
      borderRadius: '5px',
      width: '50%',
    },
  };

  return (
    <Container style={styles.enquiryForm}>
      <GlobalStyles />
      <ToastContainer />
      <h1 style={styles.heading}>Enquire now</h1>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} className="mb-3">
            <Form.Group controlId="firstName">
              <Form.Label style={styles.formLabel}>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type your answer here"
                style={styles.formControl}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-3">
            <Form.Group controlId="lastName">
              <Form.Label style={styles.formLabel}>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Type your answer here"
                style={styles.formControl}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="email" className="mb-3">
          <Form.Label style={styles.formLabel}>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="john@example.com"
            style={styles.formControl}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="enquireMessage" className="mb-3">
          <Form.Label style={styles.formLabel}>Enquire Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Type your answer here"
            style={styles.formControl}
            onChange={handleChange}
          />
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button variant="primary" type="submit" style={styles.button}>
            Submit
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default EnquiryFormMobile;
